import CodeForm from '../../CodeForm'

import './default.css'

const DefaultTheme = () => (
  <div>
    <main>
      <div className="box">
        <p>
          Bitte geben Sie Ihren Teilnahmecode ein und klicken Sie auf „Start“:
        </p>
        <CodeForm />
      </div>
    </main>
    <footer>
      <a href="https://www.paedquis.de/datenschutz/">Impressum</a>
      {' | '}
      <a href="https://www.paedquis.de/impressum/">Datenschutz</a>
      {' | '}
      <a href="https://www.paedquis.de/kontakt/">Kontakt</a>
    </footer>
  </div>
)

export default DefaultTheme
