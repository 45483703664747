import { Helmet } from 'react-helmet'
import Markdown from 'markdown-to-jsx'

import CodeForm from '../../CodeForm'
import Faq from './Faq'

import prolog from './markdown/prolog.md'
import epilog from './markdown/epilog.md'

import logo from './logo_froebel_de_white.svg'
import bg from './1700.jpg'

import './froebel.css'

const FroebelTheme = () => (
  <div className="page">
    <Helmet>
      <title>FRÖBEL - Mitarbeiterinnen- und Mitarbeiterbefragung</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/froebel/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/froebel/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/froebel/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/favicons/froebel/safari-pinned-tab.svg"
        color="#78be20"
      />
      <meta name="msapplication-TileColor" content="#78be20" />
      <meta name="theme-color" content="#78be20" />
    </Helmet>
    <header role="banner">
      <div className="navbar">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="Logo: FRÖBEL - Kompetenz für Kinder" />
          </a>
        </div>
      </div>
    </header>
    <img
      className="bg"
      src={bg}
      alt="Hintergrundbild: FRÖBEL - Kompetenz für Kinder"
    />
    <div className="boxwrap">
      <div className="bx">
        <div className="bxz">
          <Markdown>{prolog}</Markdown>
          <CodeForm />
          <div className="epilog">
            <Markdown>{epilog}</Markdown>
          </div>
          <p className="FaqLink">
            <a href="#faq">Häufig gestellte Fragen</a>
          </p>
        </div>
      </div>
      <Faq />
    </div>
    <footer></footer>
    <nav role="navigation" aria-label="Rechtliches">
      <div className="legal">
        <ul>
          <li>
            <a href="https://www.paedquis.de/kontakt">Kontakt</a>
          </li>
          <li>
            <a href="https://www.paedquis.de/impressum">Impressum</a>
          </li>
          <li>
            <a href="https://www.paedquis.de/datenschutz">
              Datenschutzerklärung
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
)

export default FroebelTheme
