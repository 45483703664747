export default {
  froebel: [
    'www.froebel-mab.de',
    'froebel-mab.de',
    'www.xn--frbel-mab-17a.de',
    'xn--frbel-mab-17a.de',
    'www.xn--frbel-mab21-sfb.de',
    'xn--frbel-mab21-sfb.de',

    'localhost:3000'
  ]
}
