import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import Cleave from 'cleave.js/react'

import './CodeForm.css'

const apiURL = window.location.host.startsWith('localhost')
  ? 'http://localhost:8080/check.php'
  : '/check.php'

const defaultTexts = {
  error: 'Ungültiger Teilnahmecode',
  loading: 'Teilnahmecode wird geprüft…',
  start: 'Start'
}

const CodeForm = ({ text: overrideTexts = {} }) => {
  const ref = useRef()
  const [code, setCode] = useState('')
  const [status, setStatus] = useState(null)
  const t = {
    ...defaultTexts,
    ...overrideTexts
  }

  const handleClick = e => {
    setStatus('loading')

    axios
      .get(`${apiURL}?code=${code.toLowerCase()}`)
      .then(({ data: { url } }) => {
        setStatus(null)
        if (url) {
          window.location.replace(url)
        } else {
          setStatus('error')
          ref?.current?.focus()
        }
      })
      .catch(error => {
        setStatus('error')
        ref?.current?.focus()
      })
  }

  useEffect(() => {
    ref?.current?.focus()
  }, [])

  const valid = code.length === 9

  return (
    <div className="CodeForm">
      <div className="CodeForm__feedback">
        {status === 'error' ? (
          <div className="CodeForm__feedback--error">{t.error}</div>
        ) : status === 'loading' ? (
          <div className="CodeForm__feedback--info">{t.loading}</div>
        ) : null}
      </div>
      <div className="CodeForm__input">
        <Cleave
          rawvalue={code}
          htmlRef={hRef => (ref.current = hRef)}
          options={{
            delimiter: '-',
            blocks: [3, 3, 3],
            uppercase: true,
            rawValueTrimPrefix: true
          }}
          onChange={e => setCode(e.target.rawValue)}
          onKeyPress={e => {
            if (e.charCode === 13) {
              e.preventDefault()
              if (valid) {
                handleClick()
              }
            }
          }}
        />
      </div>
      <div className="CodeForm__submit">
        <button
          className="CodeForm__btn"
          disabled={!valid}
          onClick={handleClick}
        >
          {t.start}
        </button>
      </div>
    </div>
  )
}

export default CodeForm
