import { useUrlSearchParams } from 'use-url-search-params'
import { capitalCase } from 'change-case'

import * as themes from './themes'

import domains from './domains'

const useTheme = () => {
  const [params] = useUrlSearchParams()
  const themeParam = params.theme || null

  // Domain based theme
  const domainThemeName = Object.keys(domains).find(theme =>
    domains[theme].includes(window.location.host)
  )

  const themeName =
    themeParam && themes[capitalCase(themeParam)]
      ? capitalCase(themeParam)
      : domainThemeName ? capitalCase(domainThemeName) : 'Default'


  return themes[themeName] || themes['Default']
}

export default useTheme
