import Markdown from 'markdown-to-jsx'

import faq from './markdown/faq.md'

import './Faq.css'

const Faq = () => (
  <div className="Faq" id="faq">
    <h3>Häufig gestellte Fragen</h3>
    <Markdown>{faq}</Markdown>
  </div>
)

export default Faq
